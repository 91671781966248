import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Autocomplete,
  DialogTitle,
  Box
} from '@mui/material';
import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const LinkOrgToCompanyDialog = ({ open, setOpen, orgId, actionCount, setActionCount }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [useExisting, setUseExisting] = useState(false);

  const navigate = useNavigate();

  const getAllCompanies = () => {
    API.get('willowAPI', '/company').then((res) => {
      console.log('getting all companies');
      setCompanies(res.data);
    });
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setUseExisting(false);
  };

  const handleCreateCompanyClick = () => {
    handleClose();
    navigate({
      pathname: '/dashboard/create-company'
    });
  };

  const initialValues = {
    company_id: { id: null }
  };

  const submit = () => {
    console.log('selectedCompany');
    console.log(selectedCompany);
    const myInit = {
      body: {
        org_id: orgId,
        company_id: selectedCompany.id
      }
    };
    API.post('willowAPI', '/org/company', myInit)
      .then((res) => {
        console.log(res);
        setActionCount(++actionCount);
        handleClose();
        enqueueSnackbar('Linked New Company to Org', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      })
      .catch((error) => {
        console.log(error.message);
        handleClose();
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleBlur, handleChange, values }) => (
        <form>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Link Company to Org.</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={4}
                justifyContent="center"
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {
                    !useExisting
                    && (
                      <Box>
                        <Button onClick={() => setUseExisting(true)}>Link Existing Company</Button>
                        <Button onClick={handleCreateCompanyClick}>Create New Company</Button>
                      </Box>
                    )
                  }
                  {
                    useExisting
                    && (
                      <Autocomplete
                        fullWidth
                        id="company_id"
                        name="company_id"
                        options={companies}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => setSelectedCompany(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Companies"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company_id}
                            variant="outlined"
                          />
                        )}
                      />
                    )
                  }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={submit}
                color="primary"
                variant="contained"
                disabled={!selectedCompany}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

export default LinkOrgToCompanyDialog;
