import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Autocomplete,
  DialogTitle,
  Box
} from '@mui/material';
import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const LinkCompanyToMachineDialog = ({ open, setOpen, comId, actionCount, setActionCount }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState();
  const [valid, setValid] = useState(false);

  const getAllAvailableMachines = () => {
    API.get('willowAPI', '/machine/all').then((res) => {
      const availableMachines = [];
      if (res.data) {
        res.data.forEach((m) => {
          if (m.owner === null) {
            availableMachines.push(m);
          }
        });
        setMachines(availableMachines);
      }
    });
  };

  useEffect(() => {
    getAllAvailableMachines();
    if (selectedMachine && startDate && endDate) {
      setValid(true);
    }
  }, [selectedMachine, startDate, endDate]);

  const handleClose = () => {
    setSelectedMachine();
    setEndDate();
    setValid(false);
    setOpen(false);
  };

  const initialValues = {
    user_id: { id: null },
    perm: null
  };

  const submit = () => {
    const epochStart = new Date(startDate).getTime();
    const epochEnd = new Date(endDate).getTime();

    const myInit = {
      body: {
        company_id: Number(comId),
        machine_id: selectedMachine.machine_id,
        start_date: epochStart,
        end_date: epochEnd,
        active: true
      }
    };

    console.log(myInit);
    API.post('willowAPI', '/company/machine', myInit)
      .then((res) => {
        console.log(res);
        setActionCount(++actionCount);
        handleClose();
        enqueueSnackbar('Linked Machine to Company', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
    >
      {({ handleBlur, handleChange }) => (
        <form>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Link Machine to Company</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={4}
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      id="machines"
                      name="machines"
                      options={machines}
                      getOptionLabel={(option) => option.unit_name}
                      onChange={(e, value) => setSelectedMachine(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Machines"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      )}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        mt: 4
                      }}
                    >
                      <Box mr={2}>
                        <DesktopDatePicker
                          label="Start Date"
                          onChange={(newDate) => setStartDate(newDate)}
                          renderInput={(inputProps) => (
                            <TextField
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={startDate}
                        />
                      </Box>
                      <Box>
                        <DesktopDatePicker
                          label="End Date"
                          onChange={(newDate) => setEndDate(newDate)}
                          renderInput={(inputProps) => (
                            <TextField
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={endDate}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                color="primary"
                onClick={submit}
                variant="contained"
                disabled={!valid}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

export default LinkCompanyToMachineDialog;
