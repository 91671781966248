import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  TableBody,
  Avatar,
  Collapse,
  Grid,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { ExpandMoreOutlined } from '@mui/icons-material';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import Label from '../Label';
import SearchIcon from '../../icons/Search';
import Plus from '../../icons/Plus';
import ExpansionButton from '../buttons/ExpansionButton';
import getLabelColor from '../../utils/getLabelColor';

const OrganizationsTable = () => {
  const [expanded, setExpanded] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const getData = () => {
    API.get('willowAPI', '/org').then((res) => {
      console.log('Getting Orgs');
      console.log(res.data);
      setOrganizations(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddOrgClick = () => {
    console.log('Navigating to create org');
    navigate('/dashboard/create-org');
  };

  const handleOrgClick = (orgId) => {
    console.log('diving into org');
    const params = { orgId };
    navigate(
      {
        pathname: '/dashboard/organization',
        search: `?${createSearchParams(params)}`
      },
      { replace: false }
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const filterOrganizations = () => organizations.filter((organization) => organization.name.toLowerCase().includes(search));

  return (
    <Card>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            spacing={4}
            p={2}
            sx={{ cursor: 'pointer' }}
            onClick={handleExpandClick}
          >
            <Grid item>
              <ExpansionButton
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreOutlined />
              </ExpansionButton>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="textPrimary"
              >
                Organizations
              </Typography>
            </Grid>
          </Grid>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex'
                      }}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        placeholder="Search Organizations"
                        variant="outlined"
                        sx={{ width: 500 }}
                      />
                      <Box>
                        <Tooltip
                          arrow
                          title="Create Organization"
                          placement="left"
                        >
                          <Button
                            variant="contained"
                            onClick={handleAddOrgClick}
                          >
                            <Plus />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.default' }}>
                  <TableCell>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>Agreement</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterOrganizations(organizations).map((org) => (
                  <TableRow
                    hover
                    key={org.id}
                    onClick={() => handleOrgClick(org.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          variant="rounded"
                          {...generateAvatarLetters(org.name)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Typography fontWeight={600}>
                            {org.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Label color={getLabelColor(org.agreement)}>
                        {org.agreement}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default OrganizationsTable;
