import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  TextField,
  Divider,
  CardActions,
  CardContent,
  Button,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Avatar
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { API, Storage } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import agreementOptions from '../../utils/agreementOptions';
import statesList from '../../utils/statesList';

const CreateCompanyForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [createdCompany, setCreatedCompany] = useState();
  const [createdComId, setCreatedComId] = useState();
  const [createdComName, setCreatedComName] = useState();
  const navigate = useNavigate();

  // ID for label and input
  const labelId = 'label-Id';

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSkipPhoto = () => {
    navigate('/dashboard/administration');
  };

  const handlePictureUpload = () => {
    try {
      Storage.put(`${createdComId}_${createdComName}`, selectedImage, {
        contentType: 'image/png',
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        }
      }).then((res) => {
        console.log(res);
        enqueueSnackbar('Company Picture Uploaded', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
        console.log(res.key);
        console.log(createdCompany);
        const myInit = {
          body: {
            id: createdComId,
            logo: res.key,
            name: createdCompany.name,
            address1: createdCompany.address1,
            address2: createdCompany.address2,
            city: createdCompany.city,
            state: createdCompany.state,
            zip: createdCompany.zip,
            agreement: createdCompany.agreement
          }
        };
        console.log('saving company key');
        API.put('willowAPI', '/company', myInit).then(() => {
          enqueueSnackbar('Company Picture Updated', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          });
        });
        navigate('/dashboard/administration');
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error Uploading company logo', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%'
          }}
        >
          <CardHeader
            title={<Typography variant="h6">Create Company Form</Typography>}
          />
          <Divider />
          <Box sx={{ p: 2 }}>
            <Stepper activeStep={activeStep}>
              <Step key={1}>
                <StepLabel>Company Info</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>Upload Picture</StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Box sx={{ pt: 2, px: 2 }}>
            {activeStep === 0 ? (
              <Box>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    agreement: ''
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Company Name is required'),
                    address1: Yup.string().max(255).required('Address is required'),
                    address2: Yup.string().max(255),
                    city: Yup.string().max(20).required('Please enter a city'),
                    state: Yup.string().max(255),
                    zip: Yup.string()
                      .required()
                      .matches(/^[0-9]+$/, 'Must be only numbers')
                      .min(5, 'Must be exactly 5 digits')
                      .max(5, 'Must be exactly 5 digits'),
                    agreement: Yup.string().max(255)
                  })}
                  onSubmit={async (
                    values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                  ) => {
                    console.log('clicking');
                    const myInit = {
                      body: values,
                      headers: {}
                    };
                    setCreatedCompany(values);
                    API.post('willowAPI', '/company', myInit)
                      .then((res) => {
                        console.log('res of creating company');
                        console.log(res.data);
                        setStatus({ success: true });
                        setSubmitting(false);
                        resetForm();
                        handleNextStep();
                        setCreatedComId(res.data[0].id);
                        // Line below replaces spaces in name with _ underscores for amplify storage
                        setCreatedComName(res.data[0].name.replace(/ /g, '_'));
                        enqueueSnackbar('Company Created', {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'success'
                        });
                      })
                      .catch((error) => {
                        enqueueSnackbar('Error Creating Company', {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'error'
                        });
                        setStatus({ success: false });
                        setErrors({ submit: error.message });
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    setFieldValue,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardContent sx={{ p: 2 }}>
                        {activeStep === 0 && (
                          <Grid
                            container
                            spacing={4}
                          >
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.name && errors.name)}
                                fullWidth
                                required
                                helperText={touched.name && errors.name}
                                label="Name"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(
                                  touched.address1 && errors.address1
                                )}
                                fullWidth
                                required
                                helperText={touched.address1 && errors.address1}
                                label="Address Line 1"
                                name="address1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address1}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(
                                  touched.address2 && errors.address2
                                )}
                                fullWidth
                                helperText={touched.address2 && errors.address2}
                                label="Address Line 2"
                                name="address2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address2}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.city && errors.city)}
                                fullWidth
                                required
                                helperText={touched.city && errors.city}
                                label="City"
                                name="city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <Autocomplete
                                id="state"
                                name="state"
                                value={values.state}
                                options={statesList}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                  setFieldValue('state', value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    error={Boolean(
                                      touched.state && errors.state
                                    )}
                                    helperText={touched.state && errors.state}
                                    required
                                    margin="normal"
                                    label="State"
                                    fullWidth
                                    name="state"
                                    {...params}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <TextField
                                error={Boolean(touched.zip && errors.zip)}
                                fullWidth
                                required
                                helperText={touched.zip && errors.zip}
                                label="Zip Code"
                                name="zip"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.zip}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                            >
                              <Autocomplete
                                id="agreement"
                                name="agreement"
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={values.agreement}
                                options={agreementOptions}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                  setFieldValue('agreement', value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    error={Boolean(
                                      touched.agreement && errors.agreement
                                    )}
                                    helperText={
                                      touched.agreement && errors.agreement
                                    }
                                    required
                                    margin="normal"
                                    label="Agreement"
                                    fullWidth
                                    name="agreement"
                                    {...params}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </CardContent>
                      <Divider />
                      <CardActions
                        sx={{
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          {activeStep === 1 ? 'Finish' : 'Submit'}
                        </Button>
                      </CardActions>
                    </form>
                  )}
                </Formik>
              </Box>
            ) : (
              <Box>
                <CardContent>
                  <Box sx={{ mb: 5 }}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          border: (theme) => `1px dashed ${theme.palette.divider}`,
                          borderRadius: '50%',
                          position: 'relative'
                        }}
                      >
                        <Avatar
                          src={imageUrl}
                          sx={{
                            height: 162,
                            width: 162
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                    >
                      <>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      </>
                      <label htmlFor={labelId}>
                        <Button
                          variant="contained"
                          component="span"
                        >
                          {imageUrl ? 'Change Photo' : 'Upload Photo'}
                        </Button>
                      </label>
                      <input
                        id={labelId}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                      />
                    </Box>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions
                  sx={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    onClick={handleSkipPhoto}
                  >
                    Skip for now
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={handlePictureUpload}
                    disabled={!selectedImage}
                  >
                    {activeStep === 1 ? 'Finish' : 'Create'}
                  </Button>
                </CardActions>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CreateCompanyForm;
