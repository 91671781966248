import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Card,
  Button,
  Avatar,
  Tooltip
} from '@mui/material';
import { API } from 'aws-amplify';
import { Edit } from '@mui/icons-material';
import Plus from '../../icons/Plus';
import Trash from '../../icons/Trash';
import LinkOrgToUserDialog from '../dialogs/LinkOrgToUserDialog';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import RemoveOrgUserConfDialog from '../dialogs/RemoveOrgUserConfDialog';

// TO-DO
// Add graphic to empty table state
// Maybe a marijuana graphic? Maybe a tooltip or a character pointing to the add button?

const OrgUsersTable = ({ orgId }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [actionCount, setActionCount] = useState(0);

  const getOrgUsers = () => {
    API.get('willowAPI', `/org/user/${orgId}`).then((res) => {
      console.log('Getting Org Users');
      console.log(res.data);
      setUsers(res.data);
      setLoading(false);
    }).catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getOrgUsers();
  }, [open, confirmOpen]);

  const formatUserName = (first, last) => {
    const fullName = first.concat(' ', last);
    return fullName;
  };

  const handleAddUserClick = () => {
    setOpen(true);
  };

  const toggleEditMode = () => {
    setActionCount(0);
    setEditMode(false);
  };

  const handleremoveUserClick = (user) => {
    setUserToRemove(user);
    setConfirmOpen(true);
  };

  const handleRemoveUserClose = () => {
    setUserToRemove();
    setConfirmOpen(false);
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={editMode ? 4 : 3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      my: 2
                    }}
                  >
                    <Typography variant="h5">Users</Typography>
                    {
                      !editMode
                      && (
                        <Tooltip
                          title={users.length ? 'Edit' : 'Add'}
                          placement="left"
                          arrow
                        >
                          {
                            users.length ? (
                              <Button
                                onClick={() => setEditMode(true)}
                                variant="contained"
                              >
                                <Edit />
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleAddUserClick}
                              >
                                <Plus />
                              </Button>
                            )
                          }
                        </Tooltip>
                      )
                    }
                    {
                      editMode
                      && (
                        <Box>
                          <Button
                            variant="text"
                            onClick={toggleEditMode}
                            sx={{ mr: 2 }}
                          >
                            { actionCount > 0 ? 'Done' : 'Cancel' }
                          </Button>
                          <Tooltip
                            title="Add User"
                            placement="top"
                            arrow
                          >
                            <Button
                              variant="contained"
                              onClick={handleAddUserClick}
                            >
                              <Plus />
                            </Button>
                          </Tooltip>
                        </Box>
                      )
                    }
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'background.default' }}>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Title</TableCell>
                {
                  editMode
                  && (
                    <TableCell align="center">Delete</TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length ? (
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          {...generateAvatarLetters(formatUserName(user.first, user.last))}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            color="inherit"
                            variant="subtitle2"
                          >
                            {formatUserName(user.first, user.last)}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{user.perm}</TableCell>
                    <TableCell>{user.title}</TableCell>
                    {
                      editMode
                      && (
                        <TableCell align="center">
                          <Button onClick={() => handleremoveUserClick(user)}>
                            <Trash color="error" />
                          </Button>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={editMode ? 4 : 3}
                  align="center"
                >
                  No Org. Users
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Card>
      )}
      <LinkOrgToUserDialog
        open={open}
        setOpen={setOpen}
        orgId={orgId}
        actionCount={actionCount}
        setActionCount={setActionCount}
        orgUsers={users}
      />
      <RemoveOrgUserConfDialog
        open={confirmOpen}
        handleClose={handleRemoveUserClose}
        user={userToRemove}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
    </>
  );
};

export default OrgUsersTable;
