import {
  Box,
  Grid,
  Card,
  CardHeader,
  TextField,
  Divider,
  CardActions,
  CardContent,
  Button,
  Autocomplete
} from '@mui/material';
import * as Yup from 'yup';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import agreementOptions from '../../utils/agreementOptions';

const CreateOrgForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%',
            p: 3
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
              agreement: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255),
              address1: Yup.string().max(255),
              address2: Yup.string().max(255),
              city: Yup.string().max(20),
              state: Yup.string().max(255),
              zip: Yup.string(),
              agreement: Yup.string().max(255)
            })}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              const myInit = {
                body: values,
                headers: {}
              };
              API.post('willowAPI', '/org', myInit)
                .then((res) => {
                  console.log(res);
                  setStatus({ success: true });
                  setSubmitting(false);
                  resetForm();
                  enqueueSnackbar('Organization Created', {
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'top'
                    },
                    variant: 'success'
                  });
                  navigate('/dashboard/administration');
                })
                .catch((error) => {
                  setStatus({ success: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                  enqueueSnackbar(error.message, {
                    anchorOrigin: {
                      horizontal: 'right',
                      vertical: 'top'
                    },
                    variant: 'error'
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              setFieldValue,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <CardHeader title="Organization" />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                  >
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        required
                        helperText={touched.name && errors.name}
                        label="Name"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <Autocomplete
                        id="agreement"
                        name="agreement"
                        value={values.agreement}
                        options={agreementOptions}
                        getOptionLabel={(option) => (option)}
                        onChange={(e, value) => {
                          setFieldValue('agreement', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={Boolean(touched.agreement && errors.agreement)}
                            helperText={touched.agreement && errors.agreement}
                            required
                            margin="normal"
                            label="Agreement"
                            fullWidth
                            name="agreement"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions
                  sx={{
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Save Organization
                  </Button>
                </CardActions>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

export default CreateOrgForm;
