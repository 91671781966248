import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  TableBody,
  Avatar,
  Link,
  Grid,
  Collapse,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { ExpandMoreOutlined } from '@mui/icons-material';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import Label from '../Label';
import SearchIcon from '../../icons/Search';
import Plus from '../../icons/Plus';
import ExpansionButton from '../buttons/ExpansionButton';

const UsersListTable = ({ expandable }) => {
  const [expanded, setExpanded] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const getAllUsers = () => {
    API.get('willowAPI', '/user/all').then((res) => {
      console.log('Getting All Users');
      console.log(res);
      console.log(res.data);
      setUsers(res.data);
      setLoading(false);
    });
  };

  const changePage = (user) => {
    const params = { userId: user.id };
    navigate({
      pathname: '/dashboard/user-details',
      search: `?${createSearchParams(params)}`
    }, { replace: false, state: user });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const fullName = (fName, lName) => `${fName} ${lName}`;

  const filterCompanies = () => users.filter((user) => fullName(user.first, user.last).toLowerCase().includes(search));

  return (
    <Card>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {expandable && (
            <Grid
              container
              alignItems="center"
              spacing={4}
              p={2}
              sx={{ cursor: 'pointer' }}
              onClick={handleExpandClick}
            >
              <Grid item>
                <ExpansionButton
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreOutlined />
                </ExpansionButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  color="textPrimary"
                >
                  Users
                </Typography>
              </Grid>
            </Grid>
          )}
          <Collapse
            in={expandable === false ? true : expanded}
            timeout="auto"
            unmountOnExit
          >
            <Table>
              <TableHead>
                {expandable === false && (
                  <TableRow>
                    <TableCell
                      colSpan="3"
                      sx={{ borderBottom: 'none' }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="textPrimary"
                        >
                          Users
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <TextField
                        sx={{ width: 500 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        placeholder="Search Users"
                        variant="outlined"
                      />
                      {expandable && (
                        <Box
                          sx={{
                            m: 1
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="left"
                            title="Create User"
                          >
                            <Button variant="contained">
                              <Plus />
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.default' }}>
                  <TableCell>
                    <Typography>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Company
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      Title
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterCompanies(users).map((user) => (
                  <TableRow
                    hover
                    key={user.id}
                    onClick={() => changePage(user)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          variant="circular"
                          {...generateAvatarLetters(fullName(user.first, user.last))}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {`${user.first} ${user.last}`}
                          </Link>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {user.company}
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Label color="primary">
                        {user.title}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default UsersListTable;
