import { Grid } from '@mui/material';

import OrganizationsTable from './OrganizationsTable';
import CompaniesTable from './CompaniesTable';
import UsersListTable from './UsersListTable';

const OperatorsOverview = () => (
  <>
    <Grid
      container
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      spacing={3}
    >
      <Grid item>
        <OrganizationsTable />
      </Grid>
      <Grid item>
        <CompaniesTable expandable />
      </Grid>
      <Grid item>
        <UsersListTable expandable />
      </Grid>
    </Grid>
  </>
);

export default OperatorsOverview;
