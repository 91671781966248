import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { Storage } from 'aws-amplify';
import useAuth from '../../hooks/useAuth';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import WillowLogoDark from '../WillowLogoDark.png';
import WillowLogoLight from '../WillowLogoLight.png';
import useSettings from '../../hooks/useSettings';

// To-DO
// Cache the logo link

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { settings } = useSettings();
  const [logo, setLogo] = useState();
  const { user } = useAuth();

  useEffect(() => {
    const fetchLogo = async () => {
      if (user.logo === 'none') {
        const getLogo = settings.theme === 'LIGHT' ? WillowLogoLight : WillowLogoDark;
        setLogo(getLogo);
      } else {
        const getLogo = await Storage.get(user.logo);
        setLogo(getLogo);
      }
    };
    fetchLogo();
  }, []);

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            size="large"
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/dashboard/tumblers">
            <img
              src={settings.theme === 'LIGHT' ? WillowLogoLight : WillowLogoDark}
              style={{ height: 32, width: 32 }}
              alt="willow"
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover logo={logo} />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
