import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Card,
  Button,
  Link,
  Tooltip,
  TableContainer
} from '@mui/material';
import { API } from 'aws-amplify';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import Plus from '../../icons/Plus';
import Trash from '../../icons/Trash';
import LinkCompanyToMachineDialog from '../dialogs/LinkCompanyToMachine';
import RemoveMachineConfDialog from '../dialogs/RemoveMachineConfDialog';

// TO-DO
// Add graphic to empty table state
// Maybe a marijuana graphic? Maybe a tooltip or a character pointing to the add button?

const CompanyMachinesTable = ({ comId }) => {
  const [machineToRemove, setMachineToRemove] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionCount, setActionCount] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [machines, setMachines] = useState([]);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const getCompanyMachines = () => {
    API.get('willowAPI', `/company/machine/${comId}`).then((res) => {
      console.log('Getting Org Machines');
      setMachines(res.data);
      setLoading(false);
    });
  };

  const handleCompanyMachineClick = (machine) => {
    console.log('digging into runs for machine');
    console.log(machine);
    const params = { machine: machine.machine_id, name: machine.unit_name };
    navigate({
      pathname: '/dashboard/runs',
      search: `?${createSearchParams(params)}`
    });
  };

  const handleRemoveMachineClick = (machine) => {
    setMachineToRemove(machine);
    setConfirmOpen(true);
  };

  const handleCloseComfirmation = () => {
    setMachineToRemove();
    setConfirmOpen(false);
  };

  useEffect(() => {
    getCompanyMachines();
  }, [open, confirmOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const convertToDateString = (epoch) => {
    const d = new Date(epoch);
    return d.toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  const toggleEditMode = () => {
    setEditMode(false);
    setActionCount(0);
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={editMode ? 4 : 3}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        my: 2
                      }}
                    >
                      <Typography variant="h5">Machines</Typography>
                      {
                        !editMode
                        && (
                          <Tooltip
                            arrow
                            placement="left"
                            title={machines.length ? 'Edit' : 'Add'}
                          >
                            {
                              machines.length ? (
                                <Button
                                  onClick={() => setEditMode(true)}
                                  variant="contained"
                                >
                                  <Edit />
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  onClick={handleOpen}
                                >
                                  <Plus />
                                </Button>
                              )
                            }
                          </Tooltip>
                        )
                      }
                      {
                        editMode
                        && (
                          <Box>
                            <Button
                              variant="text"
                              onClick={toggleEditMode}
                              sx={{ mr: 2 }}
                            >
                              {actionCount > 0 ? 'Done' : 'Cancel'}
                            </Button>
                            <Tooltip
                              title="Add Machine"
                              placement="top"
                              arrow
                            >
                              <Button
                                variant="contained"
                                onClick={handleOpen}
                              >
                                <Plus />
                              </Button>
                            </Tooltip>
                          </Box>
                        )
                      }
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.default' }}>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  {
                    editMode
                    && (
                      <TableCell align="center">Delete</TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {machines.length ? (
                  machines.map((machine) => (
                    <TableRow key={machine.unit_name}>
                      <TableCell>
                        <Link
                          color="textPrimary"
                          underline="hover"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleCompanyMachineClick(machine)}
                        >
                          {machine.unit_name}
                        </Link>
                      </TableCell>
                      <TableCell>{convertToDateString(machine.start_date)}</TableCell>
                      <TableCell>{convertToDateString(machine.end_date)}</TableCell>
                      {
                        editMode
                        && (
                          <TableCell align="center">
                            <Button onClick={() => handleRemoveMachineClick(machine)}>
                              <Trash color="error" />
                            </Button>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={editMode ? 4 : 3}
                      align="center"
                    >
                      No Company Machines
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
      <LinkCompanyToMachineDialog
        comId={comId}
        open={open}
        setOpen={setOpen}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
      <RemoveMachineConfDialog
        open={confirmOpen}
        handleClose={handleCloseComfirmation}
        machine={machineToRemove}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
    </>
  );
};

export default CompanyMachinesTable;
