import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import ConfidentCannabisTable from '../../willow_components/dashboard/ConfidentCannabisTable';

const ConfidentCannabis = () => {
  console.log(1);
  return (
    <>
      <Helmet>
        <title>Confident Cannabis</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Confident Cannabis
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ConfidentCannabisTable />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ConfidentCannabis;
