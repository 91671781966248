import { useRef, useState } from 'react';
import { Button, Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import ChevronDown from '../../icons/ChevronDown';

const MultiSelect = ({ label, onChange, options, value = [], ...other }) => {
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  console.log(options);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleChange = (event) => {
    let newValue = [...value];

    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }

    onChange?.(newValue);
  };

  return (
    <>
      <Button
        color="inherit"
        endIcon={<ChevronDown fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{ style: { width: 250 } }}
      >
        {options.length ? options.map((option) => (
          <MenuItem key={option.label}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={value.includes(option.value)}
                  onChange={handleChange}
                  value={option.value}
                />
              )}
              label={option.label}
              sx={{
                flexGrow: 1,
                mr: 0
              }}
            />
          </MenuItem>
        )) : (
          <MenuItem disabled>No Options Available</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MultiSelect;
