import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress
} from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import Search from '../../icons/Search';

import gtm from '../../lib/gtm';

const MachinesTable = () => {
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getMachines = () => {
    API.get('willowAPI', '/machine/history').then((res) => {
      console.log(res.data);
      setMachines(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getMachines();
    gtm.push({ event: 'page_view' });
  }, []);

  const changePage = (machineId, machineName) => {
    console.log('changing Page');
    const params = { mId: machineId, name: machineName };
    navigate({
      pathname: '/dashboard/machine',
      search: `?${createSearchParams(params)}`
    });
  };

  return (
    <>
      <Card>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                m: -1,
                p: 2
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: '100%',
                  width: 500
                }}
              >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Machines"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box sx={{ minWidth: 700 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'background.default' }}>
                    <TableCell>Unit Name</TableCell>
                    <TableCell>Company</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {machines.map((machine) => (
                    <TableRow
                      hover
                      key={machine.id}
                      onClick={() => changePage(machine.machine_id, machine.unit_name)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar
                            sx={{
                              height: 42,
                              width: 42
                            }}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Link
                              color="inherit"
                              variant="subtitle2"
                            >
                              {machine.unit_name}
                            </Link>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{machine.company}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

export default MachinesTable;
