import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Button
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import EditUserDialog from '../../willow_components/dialogs/EditUserDialog';

function UserManagement() {
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId');

  const getUser = () => {
    API.get('willowAPI', `/user/${userId}`).then((res) => {
      console.log('getting single user');
      setUser(res.data[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUser();
  }, [open]);

  const formatUserName = (first, last) => {
    const fullName = first.concat(' ', last);
    return fullName;
  };

  const handleEditUserClick = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>User Management</title>
      </Helmet>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8
          }}
        >
          <Container maxWidth="xl">
            <Grid container>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={3}
                item
                xs={12}
              >
                <Grid item>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    User
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  p: 3
                }}
              >
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box>
                      <Typography
                        color="textPrimary"
                        variant="h5"
                      >
                        {formatUserName(user.first, user.last)}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        sx={{ py: 2 }}
                        variant="body1"
                      >
                        {user.email}
                      </Typography>
                      <Typography>{user.title}</Typography>
                    </Box>

                    <Button
                      variant="contained"
                      onClick={handleEditUserClick}
                    >
                      Edit User
                    </Button>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Container>
          <EditUserDialog
            open={open}
            handleClose={handleClose}
            user={user}
          />
        </Box>
      )}
    </>
  );
}

export default UserManagement;
