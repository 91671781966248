import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useState } from 'react';
import { addDays, subDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const DateRangePickerDialog = ({ open, setOpen, dates, setDates, setDateChanged }) => {
  const [newDates, setNewDates] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);

  const handleClose = () => {
    console.log('fired close');
    if (newDates === dates) {
      setOpen(false);
      setDateChanged(false);
    } else {
      setDates(newDates);
      setDateChanged(true);
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setDateChanged(false);
    setOpen(false);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setNewDates([selection]);
    //  console.log('new selection');
  };

  const handleDateRangeChange = () => {
    console.log('setting New Dates');
    setDates(newDates);
    console.log(newDates);
    setDateChanged(true);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Pick A date range</DialogTitle>
      <DialogContent>
        <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={newDates}
          direction="horizontal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleDateRangeChange}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangePickerDialog;
