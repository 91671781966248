import { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Tooltip } from '@mui/material';
import { API } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import Label from '../Label';

import EditOrgInfoDialog from '../dialogs/EditOrgInfoDialog';
import OrgCompaniesTable from './OrgCompaniesTable';
import OrgUsersTable from './OrgUsersTable';
import getLabelColor from '../../utils/getLabelColor';

const OrganizationDetails = () => {
  const [organization, setOrganization] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [addCompanyOpen, setAddCompanyOpen] = useState(false);

  const orgId = searchParams.get('orgId');

  // TO-DO
  // catch array length of 0
  // Catch other errors
  const getOrganization = () => {
    API.get('willowAPI', `/org/${orgId}`).then((res) => {
      console.log('Getting Single Org');
      console.log(res.data);
      setOrganization(res.data[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getOrganization();
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Box>
          <Box
            sx={{
              mb: 2,
              pl: 3,
              pt: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}
            >
              <Typography
                variant="h5"
                color="primary"
                sx={{ mr: 2 }}
              >
                {organization.name}
              </Typography>
              <Label color={getLabelColor(organization.agreement)}>{organization.agreement}</Label>
            </Box>
            <Box mr={3}>
              <Tooltip
                title="Edit Organization"
                arrow
                placement="left"
              >
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  disableElevation
                >
                  <Edit fontSize="medium" />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box p={3}>
            <OrgCompaniesTable
              open={addCompanyOpen}
              setOpen={setAddCompanyOpen}
              organization={organization}
              orgId={orgId}
            />
          </Box>
          <Box p={3}>
            <OrgUsersTable
              organization={organization}
              orgId={orgId}
            />
          </Box>
          <EditOrgInfoDialog
            open={open}
            handleClose={handleClose}
            organization={organization}
          />
        </Box>
      )}
    </Box>
  );
};

export default OrganizationDetails;
