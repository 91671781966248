import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';

const getUserPermissions = () => {
  const [perms, setPerms] = useState({});

  const getPermissions = () => {
    API.get('willowAPI', '/user/priv/').then((res) => {
      console.log('User permissions');
      console.log(res.data);
      setPerms({
        comPerm: res.data.company_permissions,
        orgPerm: res.data.org_permissions
      });
    });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return perms;
};

export default getUserPermissions;
