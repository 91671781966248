import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography
} from '@mui/material';
import { addDays, subDays } from 'date-fns';
//  import { API } from 'aws-amplify';
import MachineExportButton from '../../willow_components/dashboard/analytics/MachineExportButton';
import useSettings from '../../hooks/useSettings';
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronRightIcon from '../../icons/ChevronRight';
import gtm from '../../lib/gtm';
import DateRangePickerDialog from '../../willow_components/dialogs/DateRangePickerDialog';
import StrainAnalysisTable from '../../willow_components/dashboard/analytics/StrainAnalysisTable';
import GeneralStatsOverview from '../../willow_components/dashboard/analytics/GeneralStatsOverview';
import OverTimeMetricGraph from '../../willow_components/dashboard/analytics/OverTimeMetricGraph';
import PercentageMetricGraph from '../../willow_components/dashboard/analytics/PercentageMetricGraph';
import AnalyticsFiltersButton from '../../willow_components/dashboard/analytics/AnalyticsFiltersButton';

const AnalyticsOverview = () => {
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [filtersToSend, setFiltersToSend] = useState({
    strains: [],
    machines: []
  });
  const { settings } = useSettings();
  // TODO this needs to track the strain and machine selections from filter
  // so that they can be passed to the lower lever
  // components for the API calls
  const [dates, setDates] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ]);

  const getFiltersToSend = (fItems) => {
    const strains = [];
    const machines = [];
    fItems.forEach((item) => {
      if (item.field === 'strain') {
        strains.push(item.value);
      } else {
        machines.push(item.value);
      }
    });
    setFiltersToSend({ strains, machines });
  };

  console.log('Sending these as filters');
  console.log(filtersToSend);

  useEffect(() => {
    if (sessionStorage.getItem('savedDR')) {
      const savedDates = JSON.parse(sessionStorage.getItem('savedDR'), (key, value) => {
        if (key === 'startDate' || key === 'endDate') {
          value = new Date(value);
        }
        return value;
      });
      setDates(savedDates);
    }
    getFiltersToSend(filterItems);
    gtm.push({ event: 'page_view' });
  }, [filterItems]);

  if (dateChanged) {
    window.sessionStorage.setItem('savedDR', JSON.stringify(dates));
  }

  const formatDates = (formDates) => {
    const startTime = formDates[0].startDate;
    const endTime = formDates[0].endDate;
    const epochSecStart = startTime.setUTCHours(0, 0, 0, 0) / 1000;
    const epochSecEnd = Math.round(endTime.setUTCHours(23, 59, 59, 999) / 1000);
    const body = {
      start: epochSecStart,
      end: epochSecEnd
    };
    return body;
  };

  const displayDates = (dateToDisp) => {
    const formattedDate = `${
      dateToDisp.getUTCMonth() + 1
    }-${dateToDisp.getUTCDate()}-${dateToDisp.getUTCFullYear()}`;

    return formattedDate;
  };

  const handleDateRangeClick = () => {
    setOpenDateRange(true);
  };

  return (
    <>
      <Helmet>
        <title>Machine Analytics</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 8,
          pb: 4
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Analytics
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/tumblers"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Analytics
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box>
                <AnalyticsFiltersButton
                  daterange={formatDates(dates)}
                  filterItems={filterItems}
                  setFilterItems={setFilterItems}
                  filtersToSend={filtersToSend}
                />
                <Button
                  color="primary"
                  endIcon={<ChevronDownIcon fontSize="small" />}
                  sx={{ ml: 2 }}
                  variant="contained"
                  onClick={handleDateRangeClick}
                >
                  {`From: ${displayDates(dates[0].startDate)} To: ${displayDates(
                    dates[0].endDate
                  )}`}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ pb: 3, mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={8}
              >
                <Box mb={3}>
                  <GeneralStatsOverview
                    daterange={formatDates(dates)}
                    dateChanged={dateChanged}
                    setDateChanged={setDateChanged}
                    filtersToSend={filtersToSend}
                  />
                </Box>
                <OverTimeMetricGraph
                  daterange={formatDates(dates)}
                  filtersToSend={filtersToSend}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Box mb={3}>
                  <PercentageMetricGraph />
                </Box>
                <Box mb={3}>
                  <StrainAnalysisTable
                    daterange={formatDates(dates)}
                    filtersToSend={filtersToSend}
                  />
                </Box>
                <Box mb={3}>
                  <MachineExportButton />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <DateRangePickerDialog
        open={openDateRange}
        setOpen={setOpenDateRange}
        dates={dates}
        setDates={setDates}
        setDateChanged={setDateChanged}
      />
    </>
  );
};

export default AnalyticsOverview;
