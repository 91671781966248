import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Avatar
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/lab';

const Machine = () => {
  const [machine, setMachine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [startEdit, setStartEdit] = useState(new Date());
  const [endEdit, setEndEdit] = useState(new Date());

  console.log(startEdit, endEdit);
  console.log(machine);

  const machineId = searchParams.get('mId');
  const machineName = searchParams.get('name');

  const getMachine = () => {
    API.get('willowAPI', `/machine/history/${machineId}`).then((res) => {
      console.log(res.data);
      setMachine(res.data);
      setLoading(false);
    });
  };

  const handleStartEdit = (newDate) => {
    setStartEdit(newDate);
  };

  const handleEndEdit = (newDate) => {
    setEndEdit(newDate);
  };

  // Call to edit machine times
  // Removed on 8/31
  // const handleSave = (relId) => {
  //   console.log(relId);
  //   const myInit = {
  //     body: {
  //       id: relId,
  //       start_date: startEdit,
  //       end_date: endEdit,
  //       active: false
  //     }
  //   };
  //   API.put('willowAPI', '/company/machine', myInit).then((res) => {
  //     console.log(res);
  //   });
  // };

  useEffect(() => {
    getMachine();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Card>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Card>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="h5"
                          >
                            {machine
                              ? `Machine History ${machineName}`
                              : 'No Machine History Found'}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: 'background.default' }}>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {machine.map((m) => (
                      <TableRow key={m}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            <Avatar />
                            <Box sx={{ ml: 1 }}>
                              <Typography>
                                {machine ? m.company_name : 'N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <DesktopDatePicker
                            showToolbar
                            disabled
                            label="Start Date"
                            onChange={(newDate) => handleStartEdit(newDate)}
                            renderInput={(inputProps) => (
                              <TextField
                                variant="outlined"
                                {...inputProps}
                              />
                            )}
                            value={!startEdit ? m.start_date : startEdit}
                          />
                        </TableCell>
                        <TableCell>
                          <DesktopDatePicker
                            label="End Date"
                            disabled
                            onChange={(newDate) => handleEndEdit(newDate)}
                            renderInput={(inputProps) => (
                              <TextField
                                variant="outlined"
                                {...inputProps}
                              />
                            )}
                            value={m.end_date}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
      </Card>
    </Box>
  );
};

export default Machine;
