import {
  Button
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import useAuth from '../../../hooks/useAuth';

// const headerCells = [
//   {
//     id: 'time',
//     label: 'Run Start Time'
//   },
//   {
//     id: 'run_time',
//     label: 'Total Run Time (hrs)'
//   },
//   {
//     id: 'strain',
//     label: 'Strain'
//   },
//   {
//     id: 'weight',
//     label: 'Weight (lbs)'
//   },
//   {
//     id: 'moisture',
//     label: 'Moisture (%)'
//   },
//   {
//     id: 'alarm_state',
//     label: 'Alarm State'
//   },
//   {
//     id: 'version',
//     label: 'Version'
//   }
// ];

const MachineExportButton = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    const myInit = {
      body: {
        email: user.email
      }
    };
    API.post(
      'willowAPI',
      '/analytics/summaries',
      myInit
    ).then(() => {
      enqueueSnackbar(`Export will be delivered to ${user.email}`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'success'
      });
    }).catch((error) => console.error(error));
  };

  return (
    <Button
      variant="contained"
      onClick={() => getData()}
      fullWidth
    >
      Export Machine Summary
    </Button>
  );
};

export default MachineExportButton;
