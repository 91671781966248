import { useMemo } from 'react';
import {
  Popover,
  Typography,
  Box,
  Input,
  Divider,
  Chip,
  Button
} from '@mui/material';
import { Search } from '@mui/icons-material';
import MultiSelect from '../MultiSelect';

const AnalyticsFiltersPopover = ({ anchorEl, onClose, open, data, filterItems, setFilterItems, ...other }) => {
  const handleDelete = (filterItem) => {
    setFilterItems((prevState) => prevState.filter((_filterItem) => !(
      filterItem.field === _filterItem.field && filterItem.value === _filterItem.value)));
  };

  console.log(data);
  console.log(filterItems);

  const machineOptions = data.machines.map((machine) => ({ value: machine.id.toString(), label: machine.unit_name }));

  const strainOptions = data.strains.map((strain) => ({ value: strain.toString(), label: strain }));

  const handleMachineChange = (values) => {
    setFilterItems((prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'machine') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        console.log('value');
        console.log(value);
        console.log(machineOptions);
        if (!valuesFound.includes(value)) {
          const option = machineOptions.find(
            (_option) => _option.value === value
          );

          console.log('here mofo');
          console.log(option);

          newFilterItems.push({
            label: 'machine',
            field: 'machine',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    });
  };

  const handleStrainChange = (values) => {
    setFilterItems((prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'strain') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = strainOptions.find(
            (_option) => _option.value === value
          );

          newFilterItems.push({
            label: 'Strain ',
            field: 'strain',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    });
  };

  const machineValues = useMemo(() => filterItems
    .filter((filteredItem) => filteredItem.field === 'machine')
    .map((filteredItem) => filteredItem.value),
  [filterItems]);

  const strainValues = useMemo(() => filterItems
    .filter((filteredItem) => filteredItem.field === 'strain')
    .map((filteredItem) => filteredItem.value),
  [filterItems]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 1000 } }}
      {...other}
    >
      <Box sx={{ py: 3 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            p: 2
          }}
        >
          <Search fontSize="small" />
          <Box
            sx={{
              flexGrow: 1,
              ml: 3
            }}
          >
            <Input
              disableUnderline
              fullWidth
              placeholder="Filter Results"
            />
          </Box>
        </Box>
        <Divider />
        {filterItems.length > 0 ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 2
            }}
          >
            {filterItems.map((filterItem) => (
              <Chip
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600
                      }
                    }}
                  >
                    <span>{filterItem.label}</span>
                    :
                    {filterItem.displayValue || filterItem.value}
                  </Box>
                )}
                onDelete={() => handleDelete(filterItem)}
                sx={{ m: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
        ) : (
          <Box sx={{ p: 3 }}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              No filters applied
            </Typography>
          </Box>
        )}
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            p: 1
          }}
        >
          <MultiSelect
            label="Machines"
            onChange={handleMachineChange}
            options={machineOptions}
            value={machineValues}
          />
          <MultiSelect
            label="Strains"
            onChange={handleStrainChange}
            options={strainOptions}
            value={strainValues}
          />
        </Box>
        <Divider />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignContent: 'center',
          pt: 2,
          pr: 2
        }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={onClose}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AnalyticsFiltersPopover;
