import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  TableBody,
  Avatar,
  Link,
  Grid,
  Collapse,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { ExpandMoreOutlined } from '@mui/icons-material';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import formatLocation from '../../utils/formatLocation';
import formatAddress from '../../utils/formatAddress';
import Label from '../Label';
import SearchIcon from '../../icons/Search';
import Plus from '../../icons/Plus';
import ExpansionButton from '../buttons/ExpansionButton';
import getLabelColor from '../../utils/getLabelColor';

const CompaniesTable = ({ expandable }) => {
  const [expanded, setExpanded] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const getAllCompanies = () => {
    API.get('willowAPI', '/company').then((res) => {
      console.log('Getting All Companies');
      console.log(res);
      console.log(res.data);
      setCompanies(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleAddCompanyClick = () => {
    console.log('Navigating to create company');
    navigate('/dashboard/create-company');
  };

  const handleCompanyClick = (comId) => {
    console.log('diving into company');
    const params = { comId };
    navigate(
      {
        pathname: '/dashboard/company',
        search: `?${createSearchParams(params)}`
      },
      { replace: false }
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const filterCompanies = () => companies.filter((company) => company.name.toLowerCase().includes(search));

  return (
    <Card>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {expandable && (
            <Grid
              container
              alignItems="center"
              spacing={4}
              p={2}
              sx={{ cursor: 'pointer' }}
              onClick={handleExpandClick}
            >
              <Grid item>
                <ExpansionButton
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreOutlined />
                </ExpansionButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  color="textPrimary"
                >
                  Companies
                </Typography>
              </Grid>
            </Grid>
          )}
          <Collapse
            in={expandable === false ? true : expanded}
            timeout="auto"
            unmountOnExit
          >
            <Table>
              <TableHead>
                {expandable === false && (
                  <TableRow>
                    <TableCell
                      colSpan="3"
                      sx={{ borderBottom: 'none' }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="textPrimary"
                        >
                          Companies
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <TextField
                        sx={{ width: 500 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        placeholder="Search Companies"
                        variant="outlined"
                      />
                      {expandable && (
                        <Box
                          sx={{
                            m: 1
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="left"
                            title="Create Company"
                          >
                            <Button
                              variant="contained"
                              onClick={handleAddCompanyClick}
                            >
                              <Plus />
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'background.default' }}>
                  <TableCell>
                    <Typography>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      Address
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      Agreement
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterCompanies(companies).map((company) => (
                  <TableRow
                    hover
                    key={company.id}
                    onClick={() => handleCompanyClick(company.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          variant="rounded"
                          {...generateAvatarLetters(company.name)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {company.name}
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {formatAddress(company.address1, company.address2)}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {formatLocation(company.city, company.state, company.zip)}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Label color={getLabelColor(company.agreement)}>
                        {company.agreement}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default CompaniesTable;
