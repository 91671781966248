export const userRoles = ['Admin', 'Customer Success'];

export const canAssignPremium = ['Admin'];

export const orgRoles = [
  'Organization Premium User',
  'Organization User',
  'Customer Success'
];

export const companyRoles = [
  'Customer Success',
  'Company Premium User',
  'Company User',
  'Premium User',
  'Basic User'
];

export const nonPremiumOrgRoles = ['Organization User', 'Basic User'];

export const nonPremiumCompanyRoles = ['Company User', 'Basic User'];
