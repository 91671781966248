import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';
import UserDetailsCard from '../../willow_components/dashboard/UserDetailsCard';

const UserDetails = (props) => {
  console.log(props);
  return (
    <>
      <Helmet>
        <title>User Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  User Details
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/administration"
                    variant="subtitle2"
                  >
                    Administration
                  </Link>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    User Details
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <UserDetailsCard />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UserDetails;
