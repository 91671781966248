import { Box, Card, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import numeral from 'numeral';
import useSettings from '../../../hooks/useSettings';

const GeneralStatsOverview = ({ daterange, dateChanged, setDateChanged, filtersToSend }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState();
  const { settings } = useSettings();

  const getStats = () => {
    setLoading(true);
    const myInit = {
      body: {
        machines: filtersToSend.machines,
        strains: filtersToSend.strains
      }
    };
    API.post(
      'willowAPI',
      `/analytics/card?start=${daterange.start}&end=${daterange.end}`,
      myInit
    ).then((res) => {
      console.log('returned from api');
      setStats(res.data);
      setLoading(false);
    }).catch((error) => console.error(error));
  };

  useEffect(() => {
    if (dateChanged || !stats) {
      getStats();
      setDateChanged(false);
    }
  }, [daterange]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={3}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Runs Completed
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {stats.runs_complete}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Errors Triggered
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  {stats.error_count}
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                fontWeight={600}
                variant="body1"
                noWrap
              >
                Average Runtime
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="h5"
                    sx={{ mr: 0.5 }}
                  >
                    {numeral(stats.avg_runtime).format('0,0')}
                  </Typography>
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="body1"
                  >
                    minutes
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                fontWeight={600}
                variant="body1"
              >
                Most Common Runtime
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="h5"
                    sx={{ mr: 0.5 }}
                  >
                    {numeral(stats.most_runtime).format('0,0')}
                  </Typography>
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="body1"
                  >
                    minutes
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Generator On Time
              </Typography>
            </Box>
            <div>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  Data
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Tooltip
                title="Time to Reach Concentration"
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  color="textPrimary"
                  noWrap
                  variant="body1"
                  fontWeight={600}
                >
                  Time To Reach Concentration
                </Typography>
              </Tooltip>
            </Box>
            <div>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  fontWeight={600}
                  sx={{ mt: 1 }}
                  variant="h5"
                >
                  Data
                </Typography>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Typography
                color="textPrimary"
                variant="body1"
                fontWeight={600}
              >
                Total Machine Runtime
              </Typography>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="h5"
                    sx={{ mr: 0.5 }}
                  >
                    {numeral(stats.total_runtime / 60).format('0,0')}
                  </Typography>
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="body1"
                  >
                    hours
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        md={3}
        sm={8}
        xs={12}
      >
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                  fontWeight={600}
                >
                  Total Weight Processed
                </Typography>
              </Box>
              {loading ? (
                <Typography variant="h5">
                  <Skeleton />
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="h5"
                    sx={{ mr: 0.5 }}
                  >
                    {settings.units === 'pounds'
                      ? numeral(stats.total_weight / 454).format('0,0.0')
                      : numeral(stats.total_weight).format('0,0.0')}
                  </Typography>
                  <Typography
                    color="primary"
                    fontWeight={600}
                    variant="body1"
                  >
                    {settings.units === 'pounds' ? 'lbs' : 'grams'}
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GeneralStatsOverview;
