import { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Tooltip, Avatar } from '@mui/material';
import { API, Storage } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import Label from '../Label';
import CompanyUsersTable from './CompanyUsersTable';
import CompanyMachinesTable from './CompanyMachinesTable';
import EditCompanyInfoDialog from '../dialogs/EditCompanyInfoDialog';
import formatAddress from '../../utils/formatAddress';
import getLabelColor from '../../utils/getLabelColor';

const CompanyDetails = () => {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyLogo, setCompanyLogo] = useState('');
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [noCompany, setNoCompany] = useState('');

  const comId = searchParams.get('comId');

  const getCompanyLogo = async (logo) => {
    const setLogo = await Storage.get(logo);
    setCompanyLogo(setLogo);
    setLoading(false);
  };

  // Gets company data
  // Sets company and noCompany props
  // Calls async call getCompanyLogo that sets company logo
  // TO-DO
  // Use NoCompany prop to redirect to a not found page
  const getCompany = () => {
    API.get('willowAPI', `/company/${comId}`).then((res) => {
      if (res.data.length) {
        setCompany(res.data[0]);
      } else {
        setNoCompany('No Company Found');
      }
      if (res.data[0].logo !== 'none') {
        getCompanyLogo(res.data[0].logo);
      } else {
        setLoading(false);
      }
    });
  };

  console.log(noCompany);

  useEffect(() => {
    getCompany();
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Box>
          <Box
            sx={{
              mb: 2,
              pl: 3,
              pt: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}
            >
              <Box sx={{ mr: 2 }}>
                <Avatar
                  alt="Company Logo"
                  src={companyLogo}
                  sx={{ height: 112, width: 112 }}
                  variant="rounded"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    sx={{ mr: 2 }}
                  >
                    {company.name}
                  </Typography>
                  <Typography>
                    <Label color={getLabelColor(company.agreement)}>{company.agreement}</Label>
                  </Typography>
                </Box>
                <Typography
                  color="textSecondary"
                  fontWeight={600}
                >
                  {formatAddress(company.address1, company.address2)}
                </Typography>
                <Typography
                  color="textSecondary"
                  fontWeight={600}
                >
                  {`${company.city}, ${company.state}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  fontWeight={600}
                >
                  {company.zip}
                </Typography>
              </Box>
            </Box>
            <Box mr={3}>
              <Tooltip
                title="Edit Company"
                arrow
                placement="left"
              >
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  disableElevation
                >
                  <Edit fontSize="medium" />
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box
            px={3}
            py={1}
          >
            <CompanyUsersTable comId={comId} />
          </Box>
          <Box
            px={3}
            pt={2}
            pb={3}
          >
            <CompanyMachinesTable comId={comId} />
          </Box>
          <EditCompanyInfoDialog
            open={open}
            handleClose={handleClose}
            company={company}
            companyLogo={companyLogo}
          />
        </Box>
      )}
    </Box>
  );
};

export default CompanyDetails;
