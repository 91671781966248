import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import OperatorsOverview from '../../willow_components/dashboard/OperatorsOverview';
import MachinesTable from '../../willow_components/dashboard/MachinesTable';
import gtm from '../../lib/gtm';

const tabs = [
  {
    label: 'Operators',
    value: 'operators'
  },
  {
    label: 'Machines',
    value: 'machines'
  }
];

const AdministrationOverview = () => {
  const [currentTab, setCurrentTab] = useState('operators');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  console.log(currentTab);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  return (
    <>
      <Helmet>
        <title>Administration</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Administration
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'operators' && <OperatorsOverview />}
            {currentTab === 'machines' && <MachinesTable />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AdministrationOverview;
