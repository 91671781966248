import React from 'react';
import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  Typography
} from '@mui/material';
import Chart from 'react-apexcharts';

const data = {
  series: [
    {
      color: '#688eff',
      data: 38,
      label: 'Average Oxygen'
    },
    {
      color: '#4CAF50',
      data: 50,
      label: 'Average Ozone'
    },
    {
      color: '#FF9800',
      data: 12,
      label: 'Average Moisture Content'
    }
  ]
};

const PercentageMetricGraph = () => {
  console.log(1);
  const chart = {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    fill: {
      opacity: 1
    },
    labels: ['Moisture Content', 'Oxygen', 'Ozone'],
    options: {
      legend: {
        position: 'top'
      }
    },
    plotOptions: {
      radialBar: {
        track: {
          background: '#e6eaec'
        }
      }
    }
  };

  const chartSeries = [1, 23, 55];

  return (
    <>
      <Card>
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Grid
            item
            xs={12}
            xl={6}
          >
            <Chart
              height="280"
              type="radialBar"
              options={chart}
              series={chartSeries}
            />
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
          >
            <List disablePadding>
              {data.series.map((item, index) => (
                <ListItem
                  disableGutters
                  divider={index + 1 < data.series.length}
                  key={item.label}
                  sx={{ display: 'flex' }}
                >
                  <Box
                    sx={{
                      border: 3,
                      borderColor: item.color,
                      borderRadius: '50%',
                      height: 16,
                      mr: 1,
                      width: 16
                    }}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {item.label}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography variant="subtitle2">
                    {item.data}
                    %
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PercentageMetricGraph;
