import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const DeleteOrgConfDialog = ({ open, setOpen, organization, closeInfo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  console.log(organization);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteOrg = () => {
    const myInit = {
      body: {
        id: organization.id
      },
      headers: {}
    };
    API.del('willowAPI', '/org', myInit)
      .then((res) => {
        console.log('delete success');
        console.log(res);
        closeInfo();
        handleClose();
        navigate('/dashboard/administration');
        enqueueSnackbar('Organization Deleted', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'success'
        });
      })
      .catch((error) => {
        console.log('Error Deleting');
        console.log(error);
        enqueueSnackbar(error.message, {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Delete Organization</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this Organization? This action is
        irreversible!
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          type="submit"
          onClick={deleteOrg}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrgConfDialog;
