import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Card,
  Button,
  Avatar,
  Link,
  Tooltip
} from '@mui/material';
import { API } from 'aws-amplify';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import Trash from '../../icons/Trash';
import Plus from '../../icons/Plus';
import Label from '../Label';
import LinkOrgToCompanyDialog from '../dialogs/LinkOrgToCompanyDialog';
import generateAvatarLetters from '../../utils/generateAvatarLetters';
import formatLocation from '../../utils/formatLocation';
import formatAddress from '../../utils/formatAddress';
import getLabelColor from '../../utils/getLabelColor';
import RemoveOrgCompanyConfDialog from '../dialogs/RemoveOrgCompanyConfDialog';

const OrgCompaniesTable = ({ open, setOpen, orgId }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionCount, setActionCount] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [companyToRemove, setCompanyToRemove] = useState();
  const [confirmOpen, setconfirmOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const getOrgCompanies = () => {
    API.get('willowAPI', `/org/company/${orgId}`).then((res) => {
      console.log('Getting Org Companies');
      console.log(res);
      setCompanies(res.data);
      setLoading(false);
    }).catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getOrgCompanies();
  }, [open, confirmOpen]);

  const handleCompanyClick = (company) => {
    console.log('diving into single company from org details');
    const params = { comId: company.id };
    navigate({
      pathname: '/dashboard/company',
      search: `?${createSearchParams(params)}`
    }, { state: { prevPath: location.state } });
  };

  const handleAddCompanyClick = () => {
    setOpen(true);
  };

  const handleRemoveCompanyClick = (company) => {
    setCompanyToRemove(company);
    setconfirmOpen(true);
  };

  const handleRemoveCompanyClose = () => {
    setCompanyToRemove();
    setconfirmOpen(false);
  };

  const toggleEditMode = () => {
    setActionCount(0);
    setEditMode(false);
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Card variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={editMode ? 4 : 3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      my: 2
                    }}
                  >
                    <Typography variant="h5">Companies</Typography>
                    {
                      !editMode
                      && (
                        <Tooltip
                          title={companies.length ? 'Edit Companies' : 'Add Company'}
                          placement="left"
                          arrow
                        >
                          {
                            companies.length ? (
                              <Button
                                onClick={() => setEditMode(true)}
                                variant="contained"
                              >
                                <Edit />
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleAddCompanyClick}
                              >
                                <Plus />
                              </Button>
                            )
                          }
                        </Tooltip>
                      )
                    }
                    {
                      editMode
                      && (
                        <Box>
                          <Button
                            variant="text"
                            onClick={toggleEditMode}
                            sx={{ mr: 2 }}
                          >
                            { actionCount > 0 ? 'Done' : 'Cancel' }
                          </Button>
                          <Tooltip
                            title="Add User"
                            placement="top"
                            arrow
                          >
                            <Button
                              variant="contained"
                              onClick={handleAddCompanyClick}
                            >
                              <Plus />
                            </Button>
                          </Tooltip>
                        </Box>
                      )
                    }
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'background.default' }}>
                <TableCell>
                  <Typography fontWeight={600}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={600}>
                    Address
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography fontWeight={600}>
                    Agreement
                  </Typography>
                </TableCell>
                {
                  editMode
                  && (
                    <TableCell align="center">
                      <Typography fontWeight={600}>
                        Delete
                      </Typography>
                    </TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.length ? (
                companies.map((company) => (
                  <TableRow
                    key={company.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => (editMode ? null : handleCompanyClick(company))}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          variant="rounded"
                          {...generateAvatarLetters(company.name)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Typography fontWeight={600}>
                            {company.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            variant="subtitle2"
                          >
                            {formatAddress(company.address1, company.address2)}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {formatLocation(company.city, company.state, company.zip)}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Label color={getLabelColor(company.agreement)}>
                        {company.agreement}
                      </Label>
                    </TableCell>
                    {
                      editMode
                      && (
                        <TableCell align="center">
                          <Button onClick={() => handleRemoveCompanyClick(company)}>
                            <Trash color="error" />
                          </Button>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))
              ) : (
                <TableCell
                  colSpan={3}
                  align="center"
                >
                  No Org. Companies
                </TableCell>
              )}
            </TableBody>
          </Table>
        </Card>
      )}
      <LinkOrgToCompanyDialog
        open={open}
        company={companyToRemove}
        setOpen={setOpen}
        orgId={orgId}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
      <RemoveOrgCompanyConfDialog
        open={confirmOpen}
        handleClose={handleRemoveCompanyClose}
        company={companyToRemove}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
    </>
  );
};

export default OrgCompaniesTable;
