import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  Grid,
  Autocomplete,
  Box,
  Avatar
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { API, Storage } from 'aws-amplify';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { DeleteForever } from '@mui/icons-material';
import agreementOptions from '../../utils/agreementOptions';
import statesList from '../../utils/statesList';
import DeleteCompanyConfDialog from './DeleteCompanyConfDialog';

// To-DO
// pull in company logo from details component
// edit storage item if one exists
// if one does not exist create one
// on delete click add delete clall to storage bucket
const EditCompanyInfoDialog = ({ open, handleClose, company, companyLogo }) => {
  const [deleteConf, setDeleteConf] = useState(false);
  const [previewImage, setPreviewImage] = useState(companyLogo);
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteClick = () => {
    setDeleteConf(true);
  };

  console.log(companyLogo);
  console.log(previewImage);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: company.name || '',
          address1: company.address1 || '',
          address2: company.address2 || '',
          city: company.city || '',
          state: company.state || '',
          zip: company.zip || '',
          agreement: company.agreement || '',
          logo: company.logo !== 'none' ? companyLogo : ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required(),
          address1: Yup.string().max(255),
          address2: Yup.string().max(255),
          city: Yup.string().max(20),
          state: Yup.string().max(255),
          zip: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Must be only numbers')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits'),
          agreement: Yup.string()
            .max(255)
            .required('Please select an agreement option')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setSubmitting(true);
          if (company.logo === 'none') {
            try {
              // Check if logo exsits
              // if it does, remove it
              // if (company.logo !== 'none') {
              //   await Storage.remove(`${company.id}_${company.name}`);
              // }
              // store new image on s3
              const newImgUpload = await Storage.put(
                `${company.id}_${company.name}`,
                values.logo,
                {
                  contentType: 'image/png'
                }
              );
              // Display success message for upload
              enqueueSnackbar('Company Picture Uploaded', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'success'
              });
              // alter init with res key
              const myInit = {
                body: {
                  id: company.id,
                  logo: newImgUpload.key,
                  name: values.name,
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                  agreement: values.agreement
                },
                headers: {}
              };
              // wait for company put
              console.log(myInit);
              API.put('willowAPI', '/company', myInit).then((res) => console.log(res));
              // display success message
              enqueueSnackbar('Company Information Updated', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'success'
              });
              handleClose();
            } catch (error) {
              enqueueSnackbar(`Error Updating Company, ${error}`, {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'error'
              });
            }
            setSubmitting(false);
          } else {
            try {
              // Delete old logo
              await Storage.remove(`${company.id}_${company.name}`, {
                level: 'public'
              });
              // Upload new logo
              const newImgUpload = await Storage.put(
                `${company.id}_${company.name}`,
                values.logo,
                {
                  contentType: 'image/png'
                }
              );
              // alter init with res
              const myInit = {
                body: {
                  id: company.id,
                  logo: newImgUpload.key,
                  name: values.name,
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                  agreement: values.agreement
                },
                headers: {}
              };
              console.log(myInit);
              API.put('willowAPI', '/company', myInit).then((res) => console.log(res));
              enqueueSnackbar('Company Information Updated', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'success'
              });
              handleClose();
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
              enqueueSnackbar(error.message, {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'error'
              });
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          values,
          isValid,
          dirty,
          isSubmitting
        }) => (
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle>
                <Typography>{company.name}</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Grid
                  container
                  direction="column"
                >
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      container
                      justifyContent="center"
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center'
                          }}
                        >
                          <Avatar
                            src={previewImage}
                            sx={{
                              height: 162,
                              width: 162
                            }}
                          />
                          <Box
                            sx={{
                              mt: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="picture">
                              <Button
                                variant="contained"
                                component="span"
                              >
                                {!companyLogo && !previewImage
                                  ? 'Add Picture'
                                  : 'Change Picture'}
                              </Button>
                            </label>
                            <input
                              id="picture"
                              name="picture"
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={(event) => {
                                console.log(values.logo);
                                setFieldValue('logo', event.target.files[0]);
                                setPreviewImage(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Name"
                          name="name"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.address1 && errors.address1)}
                          fullWidth
                          helperText={touched.address1 && errors.address1}
                          label="Address Line 1"
                          name="address1"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address1}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.address2 && errors.address2)}
                          fullWidth
                          helperText={touched.address2 && errors.address2}
                          label="Address Line 2"
                          name="address2"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address2}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helperText={touched.city && errors.city}
                          label="City"
                          name="city"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Autocomplete
                          id="state"
                          name="state"
                          value={values.state}
                          options={statesList}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setFieldValue('state', value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                              required
                              margin="normal"
                              label="State"
                              name="state"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.zip && errors.zip)}
                          fullWidth
                          helperText={touched.zip && errors.zip}
                          label="Zip Code"
                          name="zip"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.zip}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Autocomplete
                          id="agreement"
                          name="agreement"
                          value={values.agreement}
                          options={agreementOptions}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setFieldValue('agreement', value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={Boolean(
                                touched.agreement && errors.agreement
                              )}
                              helperText={touched.agreement && errors.agreement}
                              required
                              margin="normal"
                              label="Agreement"
                              fullWidth
                              name="agreement"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                  color="error"
                  onClick={handleDeleteClick}
                  startIcon={<DeleteForever />}
                  type="reset"
                >
                  Delete
                </Button>
                <Box>
                  <Button
                    type="reset"
                    onClick={handleClose}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={!(isValid && dirty) || isSubmitting}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Formik>
      <DeleteCompanyConfDialog
        open={deleteConf}
        setOpen={setDeleteConf}
        closeInfo={handleClose}
        company={company}
      />
    </>
  );
};

export default EditCompanyInfoDialog;
