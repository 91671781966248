import { useEffect, useState } from 'react';
import { Card, Typography, CircularProgress, Box, Link, Grid } from '@mui/material';
import { API } from 'aws-amplify';
import { useLocation, useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { Email, LocationCity, Phone } from '@mui/icons-material';
import Label from '../Label';

const UserDetailsCard = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState();
  const [orgs, setOrgs] = useState();
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  const userId = searchParams.get('userId');

  const getData = () => {
    const com = [];
    const org = [];
    API.get('willowAPI', `/user/priv/${userId}`).then((res) => {
      console.log(res.data);
      res.data.company.forEach((u) => {
        com.push(u);
      });
      res.data.org.forEach((u) => {
        org.push(u);
      });
      setCompanies(com);
      setOrgs(org);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleComClick = (comId) => {
    console.log('diving into company');
    const params = { comId };
    navigate(
      {
        pathname: '/dashboard/company',
        search: `?${createSearchParams(params)}`
      }
    );
  };

  const fullName = location.state.first.concat(' ', location.state.last);

  console.log(companies);
  console.log(orgs);
  return (
    <>
      <Card sx={{ p: 1 }}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Box>
            <Box
              sx={{
                mb: 2,
                pl: 3,
                pt: 3,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start'
                }}
              >
                <Typography
                  sx={{ mr: 2 }}
                  variant="h5"
                  color="textPrimary"
                >
                  {fullName}
                </Typography>
                <Label color="primary">{location.state.title}</Label>
              </Box>
            </Box>
            <Box sx={{ pl: 3, pb: 3 }}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                spacing={1}
              >
                <Grid
                  item
                  md={4}
                >
                  <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        color="primary.main"
                      >
                        User Info
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Email
                            fontSize="small"
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            fontWeight={600}
                          >
                            {location.state.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Phone
                            fontSize="small"
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            fontWeight={600}
                          >
                            {location.state.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <LocationCity
                            fontSize="small"
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            color="textSecondary"
                            fontWeight={600}
                          >
                            {location.state.state}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                >
                  <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        color="primary"
                      >
                        Companies
                      </Typography>
                    </Grid>
                    {
                      companies.length ? (
                        <>
                          {
                            companies.map((com) => (
                              <Grid
                                item
                                key={com}
                              >
                                <Link
                                  component="button"
                                  color="textPrimary"
                                  underline="none"
                                  onClick={() => handleComClick(com[0])}
                                >
                                  <Box
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
                                  >
                                    <Typography
                                      sx={{ pr: 1 }}
                                      color="primary"
                                      fontWeight={600}
                                      variant="body1"
                                    >
                                      {com[2]}
                                    </Typography>
                                    <Typography
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {`(${com[1]})`}
                                    </Typography>
                                  </Box>
                                </Link>
                              </Grid>
                            ))
                          }
                        </>
                      ) : (
                        <Grid item>
                          <Typography>No Companies</Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={4}
                >
                  <Grid
                    container
                    display="flex"
                    flexDirection="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        color="primary"
                      >
                        Organizations
                      </Typography>
                    </Grid>
                    {
                      orgs.length ? (
                        orgs.map((org) => (
                          <Grid
                            item
                            key={org}
                          >
                            <Link
                              component="button"
                              color="textPrimary"
                              underline="none"
                              onClick={() => handleComClick(org[0])}
                            >
                              <Box
                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
                              >
                                <Typography
                                  sx={{ pr: 1 }}
                                  color="primary"
                                  fontWeight={600}
                                  variant="body1"
                                >
                                  {org[2]}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {`(${org[1]})`}
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ))
                      ) : (
                        <Grid item>
                          <Typography>No Orgs.</Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
};

export default UserDetailsCard;
