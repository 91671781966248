import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';

const ConfidentCannabisTable = () => {
  const tableData = [];

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 3
        }}
      >
        <Card>
          <CardHeader title="Confident Cannabis" />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box>
                    <TextField
                      variant="outlined"
                      placeholder="Search Confident Cannabis"
                      type="search"
                      sx={{ width: '40%' }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Run Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length ? (
                tableData.map((data) => (
                  <TableRow>
                    <TableCell>{data}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center">No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </Box>
    </>
  );
};

export default ConfidentCannabisTable;
